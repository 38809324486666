<template>
  <div class="terminplaner">
    <button class="ctaButton big gerunded blau" @click="show">Terminplaner</button>
    <modal name="terminplaner" :adaptive="true" @before-open="beforeOpen" height="auto" :scrollable="true">
      <div id="medicosearchWidget" ></div>
    </modal>
  </div>
</template>

<script>
export default {
  name: "terminplaner",
  methods: {
    show() {
      this.$modal.show("terminplaner");
    },
    hide() {
      this.$modal.hide("terminplaner");
    },
    beforeOpen () {
       let terminplanerScript = document.createElement('script')
        terminplanerScript.setAttribute('src', 'https://www.medicosearch.ch/widget/api/js?key=f35b958b-2e9e-4960-b020-43b2d85af38e&version=2.0.0&container=%23medicosearchWidget&lang=de')
        document.head.appendChild(terminplanerScript)
    },
  }
};
</script>

<style lang="stylus" scoped>
button.ctaButton.gerunded {
  border-radius: 3rem;
}
#medicosearchWidget {
    height 70vh
    overflow scroll
    @media screen and (min-width: 768px){
        height 50vh
    }
    }
</style>

