<template>
  <div>
    <mq-layout :mq="['mobile', 'ipadmini', 'tablet']">
      <main-head-mobile
        :homelink="homelink"
        :navigation="navigation"
        :baseurl="baseurl"
        :langs="langs"
        :notfalltext="notfalltext"
        :notfallurl="notfallurl"
        :kontakttext="kontakttext"
        :kontakturl="kontakturl"
        :suchen="suchen"
        :suchbegriff="suchbegriff"
      ></main-head-mobile>
    </mq-layout>
    <mq-layout mq="laptop+">
      <main-head-laptop
        :homelink="homelink"
        :navigation="navigation"
        :baseurl="baseurl"
        :langs="langs"
        :notfalltext="notfalltext"
        :notfallurl="notfallurl"
        :kontakttext="kontakttext"
        :kontakturl="kontakturl"
        :suchen="suchen"
        :suchbegriff="suchbegriff"
      ></main-head-laptop>
    </mq-layout>
  </div>
</template>

<script>
import { TastyBurgerButton } from "vue-tasty-burgers";
import "vue-tasty-burgers/dist/vue-tasty-burgers.css";

export default {
  name: "MainHead",
  props: [
    "navigation",
    "baseurl",
    "langs",
    "kontakttext",
    "kontakturl",
    "notfalltext",
    "notfallurl",
    "homelink",
    "suchbegriff",
    "suchen"
  ],
  components: {
    hamberder: TastyBurgerButton
  },
  data() {
    return {
      isActive: false
    };
  },
  methods: {
    toggleMenu: function() {
      this.isActive = !this.isActive;
    }
  }
};
</script>

<style lang="stylus" >
@import '../styles/variables.styl';

#mainHead {
  padding: 1rem;
  min-height: 4rem;
  background-color: medinblue;
  color: white;
}

a {
  text-decoration: none;
  color: white;

  &:hover {
    color: white;
  }
}

.innerHead {
  margin: 1rem;
}

.sublogo {
  font-family: 'SuisseIntl', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-transform: none;
}

.hamberder {
  float: right;
  margin-top: 1rem;

  &:hover, &:focus {
    opacity: 1;
  }
}

// when Search active
// .subnav {
// display: grid;
// grid-template-columns: auto 1fr;

// @media screen and (max-width: 767px) {
// align-items: baseline;
// }
// }
.drawer {
  max-height: 0;
  overflow: hidden;
  transition: 0.5s ease all;
}

.is-open .drawer {
  margin-top: 2rem;
  max-height: 50rem;
  overflow: visible;
}
</style>