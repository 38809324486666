<template>
  <flickity ref="flickity" v-images-loaded="imgsLoaded" :options="flickityOptions">
    <slot />
  </flickity>
</template>

<script>
import imagesLoaded from "vue-images-loaded";

export default {
  data() {
    return {
      flickityOptions: {
        wrapAround: true,
        autoPlay: 5000,
        prevNextButtons: false,
        imagesLoaded: true,
        adaptiveHeight: true
      }
    };
  },
  directives: { imagesLoaded },
  mounted() {
    setTimeout(function() {
      console.log("resized");
      imgsLoaded();
    }, 2000);
  },
  methods: {
    resize() {
      this.flickity.resize();
    },
    imgsLoaded() {
      let flickityInstance = this.$refs.flickity;
      flickityInstance.resize();
    }
  }
};
</script>


<style lang="stylus" scoped></style>