<template>
  <svg
    class="arrow"
    :class="direction"
    version="1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 124 70"
  >
    <path
      d="M116 62L62 8 8 62"
      fill="none"
      :stroke="color"
      stroke-width="16"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "arrow",
  props: ["color", "direction"]
};
</script>

<style lang="stylus" scoped>
@import '../styles/variables.styl';

.arrow {
  transition: all 0.3s ease;
}

.down {
  transform: rotate(180deg);
}

.up {
  transform: rotate(0deg);
}

.left {
  transform: rotate(90deg);
}

.right {
  transform: rotate(270deg);
}
</style>