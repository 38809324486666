<template>
  <li class="contentbox" :class="{open: isOpen}">
    <div class="greyBox">
      <div class="boxNavBar" @click="toggle">
        <h3 class="titel" v-html="contentbox.contentbox.titel"></h3>
        <mq-layout :mq="['mobile', 'ipadmini']">
          <arrow :direction="direction" color="black"></arrow>
        </mq-layout>
      </div>
      <div class="boxContent">
        <article class="kurztext" v-html="contentbox.contentbox.kurztext"></article>
        <a
          v-if="contentbox.contentbox.linktext"
          class="blocklink"
          :href="contentbox.contentbox.link"
          :target="contentbox.contentbox.target"
        >
          <h4>{{contentbox.contentbox.linktext}}</h4>
        </a>
      </div>
    </div>

    <mq-layout mq="tablet+" v-if="contentbox.contentbox.srcSet">
      <picture>
        <source data-sizes="35vw" :data-srcset="contentbox.contentbox.srcSetWebP" type="image/webp" />
        <img
          class="lazyload"
          :src="contentbox.contentbox.basepixel"
          data-sizes="100vw"
          :data-srcset="contentbox.contentbox.srcSet"
          alt="contentbox.contentbox.titel"
          height="auto"
          width="100%"
        />
      </picture>
    </mq-layout>
  </li>
</template>

<script>
export default {
  name: "contentbox",
  props: ["contentbox"],
  data: function() {
    return {
      isOpen: false,
      direction: "down"
    };
  },
  methods: {
    toggle: function() {
      this.isOpen = !this.isOpen;
      if (this.direction == "down") {
        this.direction = "up";
      } else {
        this.direction = "down";
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
@import '../styles/variables.styl';

.contentbox {
  display: flex;
  flex-direction: column;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 549px) {
    .boxContent {
      max-height: 0;
      overflow: hidden;
      transition: 0.5s ease max-height;
      transition-delay: 0.2s;

      .kurztext {
        opacity: 0;
        transition: 0.5s ease opacity;
        transition-delay: 0.4s;
      }

      .blocklink {
        opacity: 0;
        transition: 0.5s ease opacity;
        transition-delay: 0.6s;
      }
    }

    &.open .boxContent {
      max-height: 20rem;

      .kurztext, .blocklink {
        opacity: 1;
      }
    }
  }

  .boxContent {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .greyBox {
    min-height: 4rem;
    background-color: medinGrey;
    padding: 1rem;
    margin-bottom: 1rem;
    flex: 1;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px) {
      margin-bottom: 0;
    }

    .titel, .kurztext {
      flex: 1;
    }

    .kurztext {
      margin-bottom: 2rem;
      margin-top: 2rem;
    }

    .boxNavBar {
      display: grid;
      grid-template-columns: auto 1.5rem;
      grid-template-rows: 1fr;
    }
  }

  h3 {
    margin-bottom: 0;
  }

  .blocklink {
    height: 2rem;
    width: fit-content;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 1rem;
    padding-right: 2rem;
    margin-bottom: 1.5rem;
  }
}
</style>