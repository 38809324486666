<template>
  <div class="langfilter">
    <ul>
      <li v-for="button in this.buttons" :key="button.id">
        <button @click="setActive(button)" :class="{ active: isActive(button.id) }">{{button.text}}</button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "langfilter",
  data() {
    return {
      buttons: [
        { id: 1, text: "DE", filter: "Deutsch" },
        { id: 2, text: "FR", filter: "Francais" },
        { id: 3, text: "IT", filter: "Italiano" },
        { id: 4, text: "EN", filter: "English" },
        { id: 5, text: "X", filter: "all" }
      ],
      activeNode: 5
    };
  },
  methods: {
    isActive: function(index) {
      return this.activeNode === index;
    },
    setActive: function(button) {
      this.$emit("setFilter", button.filter);
      this.activeNode = button.id;
    }
  }
};
</script>

<style lang="stylus" scoped>
@import '../styles/variables.styl';

.langfilter {
  text-align: right;
  margin-bottom: 0.5rem;

  @media screen and (min-width: 550px) {
    text-align: left;
  }

  p {
    color: medinGreenIntense;
    font-size: 100%;
    display: inline;
  }

  button {
    border-radius: 1rem;
    min-width: 3rem;
    min-height: 1.5rem;
    border: none;
    background-color: medinGreenDamped;
    color: medinGreenIntense;

    &.active {
      background-color: medinGreenIntense;
      color: medinGreenDamped;
    }

    &:hover {
      border: 1px solid medinGreenIntense;
    }
  }
}

ul {
  text-align: right;
  display: inline;

  button {
    color: medinGreenDamped;
    transition: color 0.3s ease-in-out;
  }
}

li {
  display: inline;
  margin-left: 0.5rem;

  @media screen and (min-width: 550px) {
    margin-right: 0.5rem;
    margin-left: 0;
  }

  &.active button {
    color: medinGreenIntense;
  }

  button:hover {
    color: medinGreenIntense;
  }
}
</style>