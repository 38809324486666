<template>
  <div class="search">
    <svg
      class="searchglass"
      version="1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 148 156"
      @click="$modal.show('search')"
    >
      <path
        d="M140 148L86 94"
        fill="none"
        stroke="#ffffff"
        stroke-width="16"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <ellipse
        transform="matrix(-1 0 0 -1 65 127)"
        cx="9"
        cy="71"
        rx="47"
        ry="47"
        fill="none"
        stroke="#ffffff"
        stroke-width="16"
        stroke-linecap="round"
      />
    </svg>
    <modal name="search" :adaptive="true">
      <div id="searchContainer">
        <form :action="url + '/search' + '/results'" autocomplete="on">
          <input id="search" name="search" type="text" :placeholder="suchbegriff" autofocus />
          <input id="search_submit" :value="suchen" type="submit" />
        </form>
      </div>/
    </modal>
  </div>
</template>

<script>
export default {
  name: "siteSearch",
  props: ["baseurl", "langs", "suchbegriff", "suchen"],
  computed: {
    url: function() {
      if (!this.langs[0].active) {
        return this.baseurl + "/fr/";
      } else {
        return this.baseurl;
      }
    }
  },
  methods: {
    show() {
      this.$modal.show("search");
    },
    hide() {
      this.$modal.hide("search");
    }
  }
};
</script>

<style lang="stylus">
@import '../styles/variables.styl';

.searchglass {
  width: 1.25rem;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 767px) {
    &::after {
      content: ' Suche';
    }
  }
}

.v--modal {
  h2 {
    color: medinBlue;
    margin-left: 1rem;
    margin-top: 1rem;
  }
}

#searchContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  form {
    width: 80%;
    max-width: 400px;
  }

  input {
    display: block;
    border: 0;
    border-bottom: 1px solid medinBlue;
    width: 100%;
    margin-bottom: 1rem;
    height: 3rem;
    font-size: 2rem;

    &::placeholder {
      color: medinBlue;
      font-size: 20;
      font-size: 1.5rem;
      padding-left: 1rem;
    }

    &[type='submit'] {
      width: 50%;
      border-bottom: 0;
      font-size: 1rem;

      &:hover {
        cursor: pointer;
      }

      @media screen and (min-width: 1920px) {
        max-width: 121px;
        max-width: 7.56rem;
      }

      background-color: medinBlue;
      color: white;
      float: right;
    }
  }
}

#closeButton {
  color: medinBlue;
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 2em;
}
</style>



