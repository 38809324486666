// import '@babel/polyfill';
import "polyfill-array-includes";
import Vue from "vue";
import Components from "./components";
import "./app.css";
import VueMq from "vue-mq";
import VModal from "vue-js-modal";
import VueCarousel from "vue-carousel";
import * as TastyBurgerButton from "vue-tasty-burgers";

Vue.config.productionTip = false;

Vue.use(VueMq, {
  breakpoints: {
    mobile: 450,
    ipadmini: 550,
    tablet: 768,
    laptop: 1080,
    desktop: 1640,
    xxldesktop: Infinity
  }
});
Vue.use(TastyBurgerButton);
Vue.use(VModal);
Vue.use(VueCarousel);

new Vue({
  el: "#app",

  delimiters: ["${", "}"]
});
