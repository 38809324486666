<template>
  <nav>
    <ul class="parents">
      <li
        v-for="navpoint in navigation"
        :key="navpoint.id"
        :class="{ childActive: navpoint.parent.menupunktActive }"
      >
        <a v-if="navpoint.parent.menupunktIsLink" :href="navpoint.parent.menupunktUrl">
          <h2 class="mainKnot">{{navpoint.parent.menupunktTitel}}</h2>
        </a>
        <h2 class="mainKnot" v-else>{{navpoint.parent.menupunktTitel}}</h2>
        <ul class="children" v-show="hover">
          <li
            v-for="child in navpoint.parent.children"
            :key="child.id"
            :class="{ active: child.active }"
          >
            <a :href="child.url">
              <h3>{{child.title}}</h3>
            </a>
          </li>
        </ul>
      </li>
      <li class="subnav">
        <lang-switcher :langs="langs"></lang-switcher>
        <site-search :baseurl="baseurl" :langs="langs" :suchen="suchen" :suchbegriff="suchbegriff"></site-search>
      </li>
    </ul>
    <div class="ctaCont">
      <cta bgc="red" :text="notfalltext" :link="notfallurl" baseurl rounded="rounded"></cta>
    </div>
  </nav>
</template>

<script>
export default {
  name: "MainNavDesktop",
  props: [
    "navigation",
    "baseurl",
    "langs",
    "kontakttext",
    "kontakturl",
    "notfalltext",
    "notfallurl",
    "hover",
    "suchbegriff",
    "suchen"
  ]
};
</script>

<style lang="stylus" scoped>
nav {
  margin-top: 1.45rem;

  @media screen and (min-width: 1920px) {
    margin-top: 1rem;

    li:not(:last-child) {
      padding-top: 0.15rem;
    }
  }
}

.parents {
  display: grid;
  grid-template-columns: repeat(4, 1fr) 9rem; // 6rem, if search is on
  grid-template-rows: auto;

  .mainKnot {
    margin-bottom: 1rem;

    @media screen and (min-width: 1920px) {
      margin-bottom: 0.8rem;
    }
  }
}

.children {
  h3 {
    font-family: 'SuisseIntl', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1.4;
    text-transform: none;
    margin-bottom: 0;
  }
}

.ctaCont {
  width: calc(100% - 9rem);

  .cta {
    margin-left: 75%;
    position: relative;
    bottom: 4rem;
  }
}

.subnav {
  div {
    display: inline-block;
    margin-top: -0.2rem;

    @media screen and (min-width: 1350px) {
      margin-top: -0.1rem;
    }

    @media screen and (min-width: 1920px) {
      margin-top: 0;
    }

    &.search {
      margin-left: 1rem;
    }
  }
}
</style>