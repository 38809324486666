<template>
  <div class="onoffswitch">
    <input
      type="checkbox"
      name="onoffswitch"
      class="onoffswitch-checkbox"
      id="myonoffswitch"
      :checked="langs[0].active"
      @change="toggle(langs)"
    />
    <label class="onoffswitch-label" for="myonoffswitch">
      <span class="onoffswitch-inner"></span>
      <span class="onoffswitch-switch"></span>
    </label>
  </div>
</template>

<script>
export default {
  props: ["langs"],
  methods: {
    toggle: function(langs) {
      setTimeout(() => {
        if (langs[0].active) {
          window.location.href = langs[1].url;
        } else {
          window.location.href = langs[0].url;
        }
      }, 1);
    }
  }
};
</script>

<style lang="stylus" scoped>
@import '../styles/variables.styl';

.onoffswitch {
  position: relative;
  width: 55px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid medinWhite;
  border-radius: 50px;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before, .onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 18px;
  padding: 0;
  line-height: 18px;
  font-size: 10px;
  color: white;
  box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: 'FR';
  padding-left: 7px;
  background-color: medinBlue;
  color: medinGrey;
}

.onoffswitch-inner:after {
  content: 'DE';
  padding-right: 7px;
  background-color: medinBlue;
  color: medinGrey;
  text-align: right;
}

.red {
  .onoffswitch-inner:before, .onoffswitch-inner:after {
    background-color: medinRed;
  }
}

.onoffswitch-switch {
  display: block;
  width: 18px;
  margin: 0px;
  background: medinWhite;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 33px;
  border: 2px solid medinWhite;
  border-radius: 50px;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}
</style>