<template>
  <li class="mitglied" @mouseover="hover = true" @mouseleave="hover = false">
    <a :href="mitglied.teammitglied.url">
      <div class="name">
        <h3 class="titel">{{mitglied.teammitglied.namen}}</h3>
        <h4 class="untertitel">{{mitglied.teammitglied.titel}}</h4>
      </div>
      <long-arrow class="ar" :color="hover ? 'white' : 'black'" direction="right"></long-arrow>
    </a>
  </li>
</template>

<script>
export default {
  name: "mitglied",
  props: ["mitglied"],
  data() {
    return {
      hover: false
    };
  }
};
</script>

<style lang="stylus" scoped>
@import '../styles/variables.styl';

.mitglied {
  background-color: medinGrey;
  padding: 1rem;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: medinBlue;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.5s;
  }

  &:hover::before {
    transform: scaleY(1);
    transform-origin: bottom;
    transition: transform 0.5s;
  }

  &:hover a {
    color: medinWhite;
  }

  .name {
    z-index: 10;
  }

  a {
    color: medinBlack;
    display: grid;
    grid-template-columns: auto 2rem;
    grid-template-rows: auto;
    grid-gap: 1rem;
    height: 100%;

    @media screen and (min-width: 550px) {
      grid-template-columns: auto 3rem;
      grid-template-rows: auto;
    }
  }

  h3 {
    margin-bottom: 0;

    &.titel {
      hyphens: none;
      word-break: break-word;
    }
  }

  .ar {
    width: 2rem;
    align-self: start;
  }
}
</style>