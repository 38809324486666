<template>
  <ul id="team">
    <li v-for="fachbereich in fachbereiche" :key="fachbereich.id">
      <h2>{{fachbereich.titel}}</h2>
      <team-boxes :teammitglieder="fachbereich.teammitglieder"></team-boxes>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Team",
  props: ["fachbereiche"]
};
</script>

<style lang="stylus" scoped>
li {
  margin-bottom: 4rem;
}

h2 {
}
</style>