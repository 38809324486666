<template>
  <nav>
    <ul>
      <li
        v-for="(navpoint, index) in navigation"
        :key="index"
        class="parent"
        :class="{activeChild: navpoint.parent.menupunktActive}"
      >
        <a v-if="navpoint.parent.menupunktIsLink" :href="navpoint.parent.menupunktUrl">
          <h2>{{navpoint.parent.menupunktTitel}}</h2>
        </a>
        <h2 v-else @click="toggle">{{navpoint.parent.menupunktTitel}}</h2>
        <ul class="children">
          <li
            v-for="child in navpoint.parent.children"
            :key="child.id"
            class="child"
            :class="{ active: child.active }"
          >
            <a :href="child.url">
              <h3>{{child.title}}</h3>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "MainNavMoblie",
  props: ["navigation"],
  methods: {
    toggle: function(event) {
      event.target.nextSibling.classList.toggle("is-open");
    }
  }
};
</script>

<style lang="stylus" scoped>
nav {
  margin-top: 2rem;
}

h2 {
  margin-bottom: 0;
}

.children {
  margin-bottom: 1rem;
  max-height: 0;
  overflow: hidden;
  transition: 0.5s ease-in-out all;

  &.is-open {
    max-height: 30rem;
  }

  h3 {
    font-family: 'SuisseIntl', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin-bottom: 0.5rem;
    text-transform: none;
    line-height: 1.2;
  }
}
</style>