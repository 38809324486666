<template>
  <section class="contentboxes">
    <ul>
      <content-box v-for="contentbox in contentboxes" :contentbox="contentbox" :key="contentbox.id"></content-box>
    </ul>
  </section>
</template>

<script>
export default {
  name: "contentboxes",
  props: ["contentboxes"],
  data() {
    return {
      contenboxes: this.contentboxes
    };
  },
  methods: {
    toggle(i) {
      console.log(i);
      console.log(this.contentboxes[i].contentbox.open);
      this.contentboxes[i].contentbox.open = !this.contentboxes[i].contentbox
        .open;
    }
  }
};
</script>
<style lang="stylus" scoped>
.contentboxes {
  ul {
    @media screen and (min-width: 550px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      grid-gap: 1rem;
    }

    @media screen and (min-width: 1100px) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: auto;
      grid-auto-rows: 1fr;
      grid-gap: 1rem;
    }
  }
}
</style>