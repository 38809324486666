<template>
  <button @click="router.go(-1)">
    <slot></slot>
  </button>
</template>

<script>
export default {};
</script>
<style lang="stylus" scoped>
@import '../styles/variables.styl';

button {
  background-color: medinBlue;
  border-radius: 1rem;
  padding-left: 1rem;
}
</style>