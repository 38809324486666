<template></template>

<script>
export default {};
</script>

<style lang="stylus">
// --------------------------------------
// all those important iports:
// -------------------------------------
@import '../styles/reset.css';
@import '../styles/variables.styl';
@import '../styles/fonts.styl';
@import '../styles/layout.styl';
@import '../styles/vue-tasty-burgers.css';
@import '../styles/iefixes.styl';
</style>
