<template>
  <modal name="notice" :adaptive="true">
    <div class="notice" v-html="text"></div>
  </modal>
</template>

<script>
export default {
  name: "Notice",
  props: ["text"],
  mounted: function() {
    this.$modal.show("notice");
  }
};
</script>

<style lang="stylus" scoped>
@import '../styles/variables.styl';

.notice {
  padding: 1rem;
}
</style>