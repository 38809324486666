<template>
  <hamberder
    :type="buttonType"
    :active="isActive"
    :size="size"
    :color="color"
    :active-color="activeColor"
  />
</template>
<script>
export default {
  data() {
    return {
      buttonType: "elastic",
      isActive: false,
      size: "xl",
      color: "green",
      activeColor: "orange"
    };
  }
};
</script>

<style lang="stylus" scoped></style>