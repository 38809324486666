<template>
  <header
    id="mainHead"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    :class="{ active: hover }"
  >
    <div class="innerHeader">
      <div class="logos">
        <a :href="homelink + '/home'">
          <logo></logo>
        </a>
        <h2 class="sublogo">Biel / Bienne</h2>
      </div>
      <main-nav-desktop
        :navigation="navigation"
        :hover="hover"
        :langs="langs"
        :notfalltext="notfalltext"
        :notfallurl="notfallurl"
        :kontakttext="kontakttext"
        :kontakturl="kontakturl"
        :baseurl="baseurl"
        :suchen="suchen"
        :suchbegriff="suchbegriff"
      ></main-nav-desktop>
    </div>
  </header>
</template>

<script>
export default {
  name: "MainHeadLaptop",
  props: [
    "navigation",
    "baseurl",
    "langs",
    "kontakttext",
    "kontakturl",
    "notfalltext",
    "notfallurl",
    "homelink",
    "suchbegriff",
    "suchen"
  ],
  data() {
    return {
      hover: false
    };
  }
};
</script>

<style lang="stylus" scoped >
@import '../styles/variables.styl';

header#mainHead {
  position: fixed;
  top: 0;
  z-index: 15;
  width: 100%;
  padding: 0;
  background-color: medinBlue;
  overflow: hidden;
  height: 5rem;

  @media screen and (min-width: 768px) {
    height: 4.3rem;
  }

  @media screen and (min-width: 1920px) {
    height: 5rem;
  }

  transition: height ease 0.3s;

  &.active {
    height: 25rem;

    .sublogo {
      opacity: 1;
    }
  }
}

.innerHeader {
  max-width: 1640px;
  margin: auto;
  height: 3rem;
  padding: 0.5rem 1rem;

  @media screen and (min-width: 1600px) {
    padding: 1rem 1rem 0.5rem;
  }

  display: grid;
  grid-template-columns: 20% auto;
  grid-template-rows: auto;
}

.sublogo {
  opacity: 0;
  margin-top: 1.5rem;

  @media screen and (min-width: 768px) {
    margin-top: 1rem;
  }

  line-height: 1.4;
  text-transform: none;
  font-weight: 400;
  margin-bottom: 0;
}
</style>