<template>
  <section class="teamboxes">
    <!-- <lang-filter :activefilter="activefilter" v-on:setFilter="onSetFilter"></lang-filter> -->
    <transition-group name="list" tag="ul" mode="in-out">
      <team-box
        v-for="mitglied in filteredList"
        :mitglied="mitglied"
        :key="mitglied.teammitglied.id"
        class="list-item"
      ></team-box>
    </transition-group>
    <!-- <ul>
      <team-box v-for="mitglied in filteredList" :mitglied="mitglied" :key="mitglied.id"></team-box>
    </ul>-->
  </section>
</template>

<script>
import { truncate } from "fs";
export default {
  name: "TeamBoxes",
  props: ["fachbereich", "teammitglieder"],
  data() {
    return {
      activefilter: "all"
    };
  },
  methods: {
    onSetFilter: function(lang) {
      console.log(lang.value);
      this.activefilter = lang;
    }
  },
  computed: {
    filteredList: function() {
      if (this.activefilter === "all") {
        return this.teammitglieder;
      } else {
        let filter = this.activefilter;

        let activesMitglied = this.teammitglieder.filter(function(mitglied) {
          let sprachen = mitglied.teammitglied.sprachen;
          let knowslanguage = sprachen.filter(function(sprache) {
            if (sprache.title === filter) {
              return true;
            } else {
              return false;
            }
          });
          if (knowslanguage.length) {
            return true;
          } else {
            return false;
          }
        });

        return activesMitglied;
      }
    }
  }
};
</script>
<style lang="stylus" scoped>
.teamboxes {
  ul {
    display: grid;
    // grid-template-rows: auto;
    grid-auto-rows: 1fr;
    grid-gap: 1rem;
    grid-template-columns: 1fr;

    @media screen and (min-width: 550px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
    }

    @media screen and (min-width: 1100px) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: auto;
    }
  }
}

// vue trans
.list-item {
  transition: all 0.3s;
}

.list-enter, .list-leave-to {
  opacity: 0;
}
</style>