<template>
  <header id="mainHead" :class="{ 'is-open': isActive }">
    <div class="topline">
      <a :href="homelink  + '/home'">
        <logo></logo>
      </a>
      <hamberder
        class="hamberder"
        type="elastic"
        color="#ffffff"
        activeColor="#ffffff"
        v-on:toggle="toggleMenu"
      ></hamberder>
    </div>
    <div class="drawer">
      <h2 class="sublogo">Biel / Bienne</h2>

      <div class="subnav">
        <lang-switcher :langs="langs"></lang-switcher>
        <site-search
          :baseurl="baseurl"
          :langs="langs"
          class="sitesearch"
          :suchen="suchen"
          :suchbegriff="suchbegriff"
        ></site-search>
      </div>
      <cta bgc="red" :text="notfalltext" :link="notfallurl" baseurl rounded="rounded"></cta>
      <cta bgc="white" :text="kontakttext" :link="kontakturl" baseurl :rounded="false"></cta>
      <main-nav-mobile :navigation="navigation"></main-nav-mobile>
    </div>
  </header>
</template>

<script>
import { TastyBurgerButton } from "vue-tasty-burgers";

export default {
  name: "MainHeadMobile",
  props: [
    "navigation",
    "baseurl",
    "langs",
    "kontakttext",
    "kontakturl",
    "notfalltext",
    "notfallurl",
    "homelink",
    "suchbegriff",
    "suchen"
  ],
  components: {
    hamberder: TastyBurgerButton
  },
  data() {
    return {
      isActive: false
    };
  },
  methods: {
    toggleMenu: function() {
      this.isActive = !this.isActive;
    }
  }
};
</script>

<style lang="stylus" scoped>
@import '../styles/variables.styl';

#mainHead {
  padding: 1rem;
  min-height: 3.4rem;
  background-color: medinBlue;
  color: white;
}

a {
  text-decoration: none;
  color: white;

  &:hover {
    color: white;
  }
}

.innerHead {
  margin: 1rem;
}

.sublogo {
  line-height: 1.2;
  text-transform: none;
  font-weight: 400;
  margin-bottom: 1rem;
}

.hamberder {
  margin-top: 1rem;

  &:hover, &:focus {
    opacity: 1;
  }
}

.topline {
  display: flex;
  justify-content: space-between;
}

.subnav {
  margin-bottom: 3rem;

  div {
    margin-bottom: 1rem;
  }
}

.drawer {
  max-height: 0;
  overflow: hidden;
  transition: 0.5s ease all;
}

.is-open .drawer {
  margin-top: 0.5rem;
  max-height: 70rem;
  max-height: fit-content;
  overflow: visible;
}
</style>