<template>
  <svg
    class="arrow"
    :class="direction"
    version="1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200 124"
  >
    <path
      d="M138 116l54-54-54-54"
      fill="none"
      :stroke="color"
      stroke-width="16"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M191 62H8" fill="none" :stroke="color" stroke-width="16" stroke-linecap="round" />
  </svg>
</template>

<script>
export default {
  name: "Longarrow",
  props: ["color", "direction"]
};
</script>

<style lang="stylus" scoped>
@import '../styles/variables.styl';

.arrow {
  transition: all 0.3s ease;
}

.down {
  transform: rotate(90deg);
}

.up {
  transform: rotate(-90deg);
}

.left {
  transform: rotate(90deg);
}

.right {
  transform: rotate(0deg);
}
</style>